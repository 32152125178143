/** @jsx jsx */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyle, jsx } from '@instructure/emotion'
import { setFeatures } from 'quiz-react-sdk/es/common/util/featureCheck'
import Alerts from '../../alerts'

import generateStyle from './styles'
import generateComponentTheme from '../../header/PageHeader/theme'

function areArraysEqual (a, b) {
  // NOTE: only works for primitive types.
  // used on the features prop, which is a list of strings
  return a.length === b.length && a.every((el, i) => el === b[i])
}

@withStyle(generateStyle, generateComponentTheme)
export class Page extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
    features: PropTypes.arrayOf(PropTypes.string),
    setSDKFeatures: PropTypes.func, // This is only a prop for testing purposes,
    makeStyles: PropTypes.func,
    styles: PropTypes.object
  }

  static defaultProps = {
    header: null,
    features: [],
    setSDKFeatures: setFeatures,
  }

  componentDidUpdate (prevProps) {
    if (!areArraysEqual(this.props.features, prevProps.features)) {
      this.setSDKFeatures()
    }
    this.props.makeStyles()
  }

  componentDidMount () {
    this.setSDKFeatures()
    this.props.makeStyles()
  }

  setSDKFeatures () {
    if (this.props.features) {
      const flagsForSDK = [
        'answer_level_feedback',
        'customizable_stimulus_orientation',
        'on_screen_calculator',
        'partial_scoring',
        'printing',
        'question_copy',
        'new_item_bank_filter_names',
        'filter_item_banks_used_by_course',
        'share_bank_on_create',
        'ice_top_nav_bar'
      ]
      const enabledFlags = flagsForSDK.filter(flag => this.props.features.includes(flag))
      this.props.setSDKFeatures(enabledFlags)
    }
  }

  render () {
    const { children, header } = this.props

    if (!header) {
      return (
        // Even though this tabIndex is not used directly,
        // for whatever reason, it makes the screenreader start at the top
        // of the page on pages without a header, when it otherwise wouldn't.
        <div className="page" css={this.props.styles.page} tabIndex="-1">
          <Alerts />
          {children}
        </div>
      )
    }

    return (
      <div
        className="page"
        css={this.props.styles.page}
        tabIndex="-1"
      >
        <Alerts />
        {header}
        <div className="withHeader" css={this.props.styles.withHeader}>
          {children}
        </div>
      </div>
    )
  }
}

export function mapStateToProps ({ features }) {
  return { features }
}

export default connect(mapStateToProps)(Page)
