import {
  ACCESS_TOKEN_STORAGE_KEY,
  SET_ACCESS_TOKEN,
  SET_RETURN_TO_URL,
  STORE_ASSIGNMENT_ID,
  SET_TIMEZONE,
  SET_PLATFORM,
  SET_RLDB_LAUNCH,
  SET_MODULE_LAUNCH,
  SET_SDK_TRANSLATIONS,
  SET_COURSE_WORKFLOW_STATE,
  SET_ITEM_BANKS_SCOPE,
  SET_CURRENT_USER,
  SET_LAUNCH_URL,
  SET_RCS_HOST,
  SET_RCS_JWT,
  SET_CANVAS_CONTEXT_ID,
  SET_CANVAS_LOCAL_CONTEXT_ID,
  SET_CANVAS_TOOL_ID,
  SET_USER_CANVAS_ID,
  SET_CONTEXT_TYPE,
  SET_CANVAS_HOST,
  SET_CANVAS_ASSIGNMENT_ID,
  SET_RESTRICT_QUANTITATIVE_DATA,
  SET_GRADING_SCHEME,
  SET_READ_ONLY
} from '../constants'

let sessionStorage
let localStorage
try {
  sessionStorage = window.sessionStorage
  localStorage = window.localStorage
} catch (e) {
  sessionStorage = null
  localStorage = null
}

function setInStorage (storage, key, value) {
  if (!storage) {
    return
  }
  try {
    if (value) {
      storage.setItem(key, value)
    } else {
      storage.removeItem(key)
    }
  } catch (e) {
    // do nothing
  }
}

export default (state = { accessToken: null, returnToUrl: null, courseWorkflowState: null }, action = {}) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      // Save the access token in sessionStorage so it persists through page reloads
      setInStorage(sessionStorage, ACCESS_TOKEN_STORAGE_KEY, action.access_token)
      return Object.assign({}, state, { accessToken: action.access_token || null })
    case SET_CURRENT_USER:
      // Save the currentUser in sessionStorage so it persists through page reloads
      setInStorage(sessionStorage, 'current_user', JSON.stringify(action.currentUser))
      return Object.assign({}, state, { currentUser: action.currentUser || null })
    case SET_RETURN_TO_URL:
      // Save the return to url in localStorage so it persists through lost sessions
      setInStorage(localStorage, 'return_to', action.url)
      return Object.assign({}, state, { returnToUrl: action.url || null })
    case STORE_ASSIGNMENT_ID:
      // Save the assignmentId in sessionStorage so it persists through page reloads
      setInStorage(sessionStorage, 'assignment_id', action.assignmentId)
      return Object.assign({}, state, { assignmentId: action.assignmentId || null })
    case SET_TIMEZONE:
      // Save the timeZone in localStorage so it persists through lost sessions
      setInStorage(localStorage, 'timezone', action.timeZone)
      return Object.assign({}, state, { timeZone: action.timeZone || null })
    case SET_PLATFORM:
      // Save the platform in sessionStorage so it persists through page reloads
      setInStorage(localStorage, 'platform', action.platform)
      return Object.assign({}, state, { platform: action.platform || null })
    case SET_RLDB_LAUNCH:
      // Save the rldb launch in sessionStorage so it persists through page reloads
      setInStorage(sessionStorage, 'rldb_launch', action.rldbLaunch)
      return Object.assign({}, state, { rldbLaunch: action.rldbLaunch || null })
    case SET_MODULE_LAUNCH:
      return Object.assign(
        {},
        state,
        {
          moduleLaunch: action.moduleLaunch || null,
          canvasAssignmentId: action.canvasAssignmentId || null,
        }
      )
    case SET_SDK_TRANSLATIONS:
      // Save the sdkTranslations in sessionStorage so they persist through page reloads
      setInStorage(localStorage, 'translations', action.sdkTranslations)
      return Object.assign({}, state, { sdkTranslations: action.sdkTranslations || null })
    case SET_COURSE_WORKFLOW_STATE:
      // Save the courseWorkflowState in sessionStorage so they persist through page reloads
      setInStorage(localStorage, 'courseWorkflowState', action.courseWorkflowState)
      return Object.assign({}, state, { courseWorkflowState: action.courseWorkflowState || null })
    case SET_ITEM_BANKS_SCOPE:
      // Save the Item Banks Scope in sessionStorage so it persists through page reloads
      setInStorage(localStorage, 'item_banks_scope', action.itemBanksScope)
      return Object.assign({}, state, { itemBanksScope: action.itemBanksScope || null })
    case SET_LAUNCH_URL:
      return Object.assign({}, state, { launchUrl: action.launchUrl || null })
    case SET_RCS_HOST:
      setInStorage(localStorage, 'rcs_host', action.rcsHost)
      return Object.assign({}, state, { rcsHost: action.rcsHost || null })
    case SET_RCS_JWT:
      setInStorage(localStorage, 'rcs_jwt', action.rcsJwt)
      return Object.assign({}, state, { rcsJwt: action.rcsJwt || null })
    case SET_CANVAS_TOOL_ID:
      setInStorage(localStorage, 'canvas_tool_id', action.canvasToolId)
      return Object.assign({}, state, { canvasToolId: action.canvasToolId || null })
    case SET_CANVAS_CONTEXT_ID:
      setInStorage(sessionStorage, 'canvas_context_id', action.canvasContextId)
      return Object.assign({}, state, { canvasContextId: action.canvasContextId || null })
    case SET_CANVAS_LOCAL_CONTEXT_ID:
      setInStorage(sessionStorage, 'canvas_local_context_id', action.canvasLocalContextId)
      return Object.assign({}, state, { canvasLocalContextId: action.canvasLocalContextId || null })
    case SET_CONTEXT_TYPE:
      setInStorage(sessionStorage, 'context_type', action.contextType)
      return Object.assign({}, state, { contextType: action.contextType || null })
    case SET_USER_CANVAS_ID:
      setInStorage(sessionStorage, 'user_canvas_id', action.userCanvasId)
      return Object.assign({}, state, { userCanvasId: action.userCanvasId || null })
    case SET_CANVAS_HOST:
      setInStorage(localStorage, 'canvas_host', action.canvasHost)
      return Object.assign({}, state, { canvasHost: action.canvasHost || null })
    case SET_CANVAS_ASSIGNMENT_ID:
      setInStorage(localStorage, 'canvas_assignment_id', action.canvasAssignmentId)
      return Object.assign({}, state, { canvasAssignmentId: action.canvasAssignmentId || null })
    case SET_RESTRICT_QUANTITATIVE_DATA:
      setInStorage(localStorage, 'restrict_quantitative_data', action.restrictQuantitativeData)
      return Object.assign({}, state, { restrictQuantitativeData: action.restrictQuantitativeData || null })
    case SET_GRADING_SCHEME:
      setInStorage(localStorage, 'grading_scheme', action.gradingScheme)
      return Object.assign({}, state, { gradingScheme: action.gradingScheme || null })
    case SET_READ_ONLY:
      setInStorage(localStorage, 'read_only_mode', action.readOnly)
      return Object.assign({}, state, { readOnly: action.readOnly || null })
    default:
      return state
  }
}
