import React from 'react'
import { SVGIcon } from '@instructure/ui-svg-images'
import t from 'format-message'

function IconUnsupportedPanda(props) {
  return (
    <SVGIcon width="159" height="162" viewBox="0 0 159 162" title={t('Unsupported Panda Icon')} {...props}>
      <g fill="none">
        <path d="M79 161.5C115.451 161.5 145 157.359 145 152.25C145 147.141 115.451 143 79 143C42.5492 143 13 147.141 13 152.25C13 157.359 42.5492 161.5 79 161.5Z" fill="black" fillOpacity="0.05"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.1386 23.1386C-7.71285 53.99 -7.71285 104.01 23.1386 134.861C53.99 165.713 104.01 165.713 134.861 134.861C165.713 104.01 165.713 53.99 134.861 23.1386C104.01 -7.71285 53.99 -7.71285 23.1386 23.1386ZM120.719 120.719C97.6784 143.76 60.3216 143.76 37.2807 120.719C35.9471 119.386 34.6907 118.004 33.5115 116.58L134.573 59.1431C141.988 79.9409 137.37 104.068 120.719 120.719ZM124.741 41.7265L23.5618 99.2305C15.957 78.3485 20.5299 54.0315 37.2807 37.2807C60.3216 14.2398 97.6784 14.2398 120.719 37.2807C122.148 38.7099 123.489 40.1941 124.741 41.7265Z" fill="#EF3E60"/>
        <path d="M22.6733 96.5988L21.5618 97.2305C13.957 76.3485 18.5299 52.0315 35.2807 35.2807C58.3216 12.2398 95.6784 12.2398 118.719 35.2807C119.054 35.615 119.383 35.9522 119.708 36.2924C96.5974 14.2446 59.9874 14.5741 37.2807 37.2807C21.229 53.3324 16.3599 76.332 22.6733 96.5988Z" fill="#E63255"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.3071 107.081C55.6814 106.775 45.354 105.571 37.8307 100.832C27.854 94.5483 35.2365 84.802 41.3366 84.0569C47.0263 83.3614 53.9022 90.7489 61.1468 104.185C60.8475 105.134 60.5684 106.101 60.3071 107.081Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M98.3323 94.5384C99.3078 94.26 100.27 93.9641 101.213 93.6483C114.774 100.657 122.28 107.403 121.684 113.104C121.045 119.216 111.429 126.768 104.972 116.902C100.103 109.463 98.7189 99.158 98.3323 94.5384Z" fill="#2D3B45"/>
        <path d="M98.7715 94.4116C98.6254 94.4543 98.479 94.4965 98.3323 94.5384C98.7189 99.1581 100.103 109.463 104.972 116.902C111.429 126.768 121.045 119.216 121.684 113.104C121.774 112.243 121.679 111.359 121.407 110.453C119.664 116.223 110.96 122.05 104.972 112.902C101.196 107.133 99.5158 99.6396 98.7715 94.4116Z" fill="#28343D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M98.3323 94.5384C99.3078 94.26 100.27 93.9641 101.213 93.6483C114.774 100.657 122.28 107.403 121.684 113.104C121.045 119.216 111.429 126.768 104.972 116.902C100.103 109.463 98.7189 99.158 98.3323 94.5384Z" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M98.9319 94C92.6098 96.1053 85.3946 97.2497 77.7321 97.2497C70.9021 97.2497 64.5841 96.4438 59.0066 94.864C56.7669 99.8791 54.23 106.305 53.6744 110.75C52.0402 122.187 61.9821 132.211 79.6359 132.211C97.2898 132.211 106.512 121.827 104.497 111.096C103.109 103.703 100.87 98.1178 98.9319 94Z" fill="white"/>
        <path d="M57.5322 123.865C62.0225 127.746 68.8808 130.211 77.6358 130.211C95.2896 130.211 104.512 119.827 102.497 109.096C101.353 103.002 99.6305 98.137 97.9753 94.309C98.2963 94.2085 98.6152 94.1054 98.9318 94C100.87 98.1178 103.108 103.703 104.497 111.096C106.512 121.827 97.2896 132.211 79.6358 132.211C69.4577 132.211 61.843 128.879 57.5322 123.865Z" fill="#EDEDED"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M113.874 137.27C111.104 123.078 104.987 114.609 104.64 111.84C105.99 121.269 98.5604 130.421 84.5 132.13L84.7967 133.463C85.8351 139.001 87.912 141.078 91.3736 142.116C98.989 144.886 115.604 143.847 113.874 137.27Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.5225 112C53.1255 114.77 46.8947 123.078 44.1255 137.27C42.3947 143.847 59.0101 144.885 66.6255 142.116C70.087 141.078 72.164 139.001 73.2024 133.462L73.4845 132.309L73.4991 131.99C60.1746 130.028 52.7302 121.617 53.5225 112Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
        <path d="M31.0326 44.6675L14.7742 33.0199C-7.58852 64.2351 -4.24547 107.392 23.1905 134.828C54.042 165.679 104.062 165.679 134.913 134.828C162.927 106.814 165.767 62.5004 142.015 31.2383L126.09 43.3376C143.812 66.6628 141.69 99.7665 120.771 120.685C97.7303 143.726 60.3736 143.726 37.3327 120.685C36.0014 119.354 34.7461 117.973 33.5671 116.548L55.3067 104.193C56.3082 101.184 57.6074 98.0193 58.8354 95.2483L58.1276 94.0028C53.0749 92.3178 48.7074 89.9495 45.2191 86.9218L23.6223 99.196C17.1103 81.325 19.4474 60.8388 31.0326 44.6675Z" fill="#EF3E60"/>
        <path d="M22.201 133.82C22.5274 134.158 22.8572 134.494 23.1907 134.828C54.0421 165.679 104.062 165.679 134.914 134.828C162.927 106.814 165.767 62.5004 142.015 31.2383L141.671 31.4997C163.617 62.6073 160.258 105.483 132.914 132.828C102.397 163.344 53.1247 163.675 22.201 133.82Z" fill="#E73255"/>
        <path d="M36.3463 119.675C35.3772 118.659 34.4507 117.616 33.5672 116.548L55.3069 104.193C56.3083 101.184 57.6075 98.0193 58.8355 95.2483L58.1277 94.0028C49 89.5 41.963 93.6706 41.5 93.5C40.3064 96.2078 54.277 99.278 53.3069 102.193L31.5672 114.548C32.7462 115.973 34.0015 117.354 35.3328 118.685C35.6676 119.02 36.0055 119.35 36.3463 119.675Z" fill="#E73255"/>
        <path d="M22.7333 96.5647L23.5 99C15.5 85 17.4119 59.1554 28.7656 43.0433L31.0327 44.6675C20.0122 60.0505 17.3603 79.338 22.7333 96.5647Z" fill="#E73255"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M34 94.5C35 97.5 39.5 100.5 44 98C47 96.3333 49.1667 94 50.5 91L42.5 85.5L38.5 88.5C34.8334 90.5 33.3334 92.5 34 94.5Z" fill="#2D3B45"/>
        <path d="M48.5 90.5C44 99.1133 37 97 33 93.5C33 96.5 36 99 41.5 99.5C45.9 99.9 49.6667 94.3333 51 91.5C50.8333 91.1667 50.5 90.9 48.5 90.5Z" fill="#28343D"/>
        <path d="M33 92.5C32 97.5 39.5 101 44.5 98.5C47.8334 96.8333 50 94.1667 51 90.5" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M98.5 94.5C93.5 96 85.3946 97.2497 77.7321 97.2497C70.9021 97.2497 64.5841 96.4438 59.0066 94.864C56.7669 99.8791 54.23 106.305 53.6744 110.75C52.0402 122.187 61.9821 132.211 79.6359 132.211C97.2898 132.211 106.512 121.827 104.497 111.096C103.109 103.703 100.438 98.6178 98.5 94.5Z" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.4328 42.7247C41.1741 42.2023 39.8252 41.4522 38.4748 40.3722C33.2825 36.2183 34.321 29.2953 34.6671 27.5645C35.0133 25.8337 37.0902 17.526 43.6671 21.3337C47.9148 23.7928 52.304 27.982 54.8822 30.6428C49.9973 33.8967 45.7662 38.0332 42.4328 42.7247Z" fill="#2D3B45"/>
        <path d="M37.4748 36.3722C38.8251 37.4522 40.1741 38.2023 41.4327 38.7246C44.3525 34.6153 47.9611 30.9317 52.0945 27.8942C53.1537 28.8903 54.101 29.8367 54.8821 30.6428C49.9972 33.8967 45.7662 38.0332 42.4327 42.7246C41.1741 42.2023 39.8251 41.4522 38.4748 40.3722C35.6366 38.1017 34.6601 35.0038 34.4146 32.3787C35.0431 33.811 36.0115 35.2016 37.4748 36.3722Z" fill="#28343D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.4328 42.7247C41.1741 42.2023 39.8252 41.4522 38.4748 40.3722C33.2825 36.2183 34.321 29.2953 34.6671 27.5645C35.0133 25.8337 37.0902 17.526 43.6671 21.3337C47.9148 23.7928 52.304 27.982 54.8822 30.6428C49.9973 33.8967 45.7662 38.0332 42.4328 42.7247Z" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M115.68 42.7247C116.939 42.2023 118.288 41.4522 119.638 40.3722C124.83 36.2183 123.792 29.2953 123.446 27.5645C123.1 25.8337 121.023 17.526 114.446 21.3337C110.198 23.7928 105.809 27.982 103.231 30.6428C108.116 33.8967 112.347 38.0332 115.68 42.7247Z" fill="#2D3B45"/>
        <path d="M118.638 36.3722C117.288 37.4522 115.939 38.2023 114.68 38.7247C112.026 34.9892 108.803 31.6055 105.134 28.7391C104.428 29.4231 103.787 30.0686 103.231 30.6429C108.116 33.8967 112.347 38.0333 115.68 42.7247C116.939 42.2023 118.288 41.4522 119.638 40.3722C124.83 36.2184 123.792 29.2953 123.446 27.5645C123.346 27.0665 123.103 26.024 122.651 24.8822C122.965 27.6005 122.935 32.9346 118.638 36.3722Z" fill="#28343D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M115.68 42.7247C116.939 42.2023 118.288 41.4522 119.638 40.3722C124.83 36.2183 123.792 29.2953 123.446 27.5645C123.1 25.8337 121.023 17.526 114.446 21.3337C110.198 23.7928 105.809 27.982 103.231 30.6428C108.116 33.8967 112.347 38.0332 115.68 42.7247Z" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.5225 66.3338C35.5225 86.4107 53.6703 97.1415 78.6186 97.1415C103.567 97.1415 123.792 85.0261 123.792 67.0261C123.792 44.6587 103.567 23.0646 78.6186 23.0646C53.6703 23.0646 35.5225 45.2184 35.5225 66.3338Z" fill="white" stroke="#152633" strokeWidth="2"/>
        <path d="M42.2778 84.5751C49.958 91.5292 62.1437 95.1415 76.6187 95.1415C101.567 95.1415 121.792 83.0261 121.792 65.0261C121.792 54.2796 117.123 43.7116 109.505 35.6313C118.298 43.9211 123.792 55.3686 123.792 67.0261C123.792 85.0261 103.567 97.1415 78.6187 97.1415C62.8146 97.1415 49.7394 92.8354 42.2778 84.5751Z" fill="#EDEDED"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M35.5225 66.3338C35.5225 86.4107 53.6703 97.1415 78.6186 97.1415C103.567 97.1415 123.792 85.0261 123.792 67.0261C123.792 44.6587 103.567 23.0646 78.6186 23.0646C53.6703 23.0646 35.5225 45.2184 35.5225 66.3338Z" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M86 78C86 79.6569 83.09 81 79.5 81C75.91 81 73 79.6569 73 78C73 76.3431 75.91 75 79.5 75C83.09 75 86 76.3431 86 78Z" fill="#152633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83 91C83 88.7909 81.433 87 79.5 87C77.567 87 76 88.7909 76 91H83Z" fill="#152633" stroke="#152633"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M104.038 82.3846C111.994 82.3846 118.615 73.2612 118.615 61.9231C118.615 55.1954 115.427 51.9386 106.537 47.5528C105.882 47.2295 103.577 46.1103 103.4 46.0237C102.117 45.3965 101.12 44.8958 100.15 44.3849C96.1762 42.2936 92.7355 43.3612 90.058 47.097C87.5232 50.6338 86 56.2432 86 61.9231C86 72.5699 95.3146 82.3846 104.038 82.3846Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M98.133 65.7497C98.133 68.3728 96.76 70.4995 95.0665 70.4995C93.373 70.4995 92 68.3728 92 65.7497C92 63.1266 93.373 61 95.0665 61C96.76 61 98.133 63.1266 98.133 65.7497Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M54.5769 82.3846C63.3008 82.3846 72.6154 72.5699 72.6154 61.9231C72.6154 56.2432 71.0922 50.6338 68.5574 47.097C65.8799 43.3612 62.4391 42.2936 58.4657 44.3849C57.4951 44.8958 56.4984 45.3965 55.2152 46.0237C55.0381 46.1103 52.7334 47.2295 52.0782 47.5528C43.1884 51.9386 40 55.1954 40 61.9231C40 73.2612 46.6219 82.3846 54.5769 82.3846Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M66.133 65.7497C66.133 68.3728 64.76 70.4995 63.0665 70.4995C61.373 70.4995 60 68.3728 60 65.7497C60 63.1266 61.373 61 63.0665 61C64.76 61 66.133 63.1266 66.133 65.7497Z" fill="white"/>
      </g>
    </SVGIcon>
  )
}

export { IconUnsupportedPanda }