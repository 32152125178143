import { connect } from 'react-redux'
import { removeAlert } from '../../actions/alerts'
import Alerts from './presenter'

const mapStateToProps = ({ alerts = {} }) => {
  return {
    alerts: Object.values(alerts)
  }
}

export default connect(mapStateToProps, {
  removeAlert
})(Alerts)
