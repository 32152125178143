const generateStyle = (componentTheme, props) => {
  const msMaxWidth = 'max-width: 48em'
  const mq = `@media screen and (${msMaxWidth})`
  return {
    skipNavigationLink: {
      padding: '1px 1px',
      position: 'absolute',
      left: '-10000px',
      top: '0',
      height: '4rem',
      lineHeight: '4rem',
      '&:focus': {
        left: '0',
        position: 'relative'
      },
      ...(props.isNewTopNavBar && {
        [mq]: {
          height: '3.5rem',
          lineHeight: '3.5rem'
        }
      })
    }
  }
}

export default generateStyle
