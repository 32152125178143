import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'format-message'
import { Alert } from '@instructure/ui-alerts'

export default class Alerts extends Component {
  static propTypes = {
    removeAlert: PropTypes.func.isRequired,
    alerts: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      message: PropTypes.string,
      variant: PropTypes.string
    }))
  }

  closeAlert = (uuid) => () => {
    this.props.removeAlert(uuid)
  }

  liveRegion = () => document.getElementById('alertHolder')

  renderAlert = (alert) => {
    // Only rendering SR alerts for now, since visual alerts
    // clash with those from the sdk
    return (
      <Alert
        key={alert.uuid}
        screenReaderOnly
        liveRegion={this.liveRegion}
        onDismiss={this.closeAlert(alert.uuid)}
        renderCloseButtonLabel={t('Close')}
        transition="none"
      >
        {alert.message}
      </Alert>
    )
  }

  render () {
    return (
      <div>
        {this.props.alerts.map(this.renderAlert)}
      </div>
    )
  }
}
