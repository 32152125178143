/** @jsx jsx */
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import t from 'format-message'

import SDKBuildingButtons from 'quiz-react-sdk/es/building/components/layout/header/SDKBuildingButtons'
import SDKMenuButton from 'quiz-react-sdk/es/common/components/SDKMenuButton'
import SDKNavBar from 'quiz-react-sdk/es/common/components/SDKNavBar'
import { withStyle, jsx } from '@instructure/emotion'
import { ScreenReaderContent } from '@instructure/ui-a11y-content'
import { IconMoreSolid } from '@instructure/ui-icons'

import { navigateTo } from '../../../actions/path'

import Header from '../PageHeader'
import SkipToContent from '../SkipToContent'

import generateStyle from './styles'
import speedGraderLink from '../../../util/speedGraderLink'
import { contentExport } from '../../../actions/apiActions'
import { exportStatus, pollForExport } from '../../../contentExports/contentExportsPoller'
import { EXPORT_STATUS_EXPORTING } from '../../../constants'

@withStyle(generateStyle, null)
export class QuizHeader extends Component {
  static propTypes = {
    activeAssignmentId: PropTypes.string,
    app: PropTypes.string,
    children: PropTypes.node,
    hamburgerButtonClick: PropTypes.func,
    navigateToBanks: PropTypes.func.isRequired,
    onTake: PropTypes.bool,
    timerRunning: PropTypes.bool,
    pollForExport: PropTypes.func, // only added as a prop for testing/stubbing purposes
    isPreview: PropTypes.bool,
    sidebarOpen: PropTypes.bool,
    speedgraderLinksEnabled: PropTypes.bool.isRequired,
    navigateTo: PropTypes.func,
    iceTopNavBarEnabled: PropTypes.bool,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
    actionItems: PropTypes.arrayOf(PropTypes.node),
    contextUuid: PropTypes.string,
    styles: PropTypes.object
  }

  static defaultProps = {
    activeAssignmentId: null,
    app: null,
    isPreview: false,
    contextUuid: null,
    timerRunning: false,
    iceTopNavBarEnabled: false,
    menuItems: [],
    navigateTo () {},
    actionItems: [],
    pollForExport
  }

  renderHamburgerButton () {
    if (!this.props.hamburgerButtonClick) {
      return
    }
    return <SDKMenuButton
      onTake={this.props.onTake}
      sidebarMenuClick={this.props.hamburgerButtonClick}
      sidebarOpen={this.props.sidebarOpen}/>
  }

  announcePreview () {
    if (this.props.isPreview) {
      return (
        <ScreenReaderContent>
          {t('You are in Preview Mode')}
        </ScreenReaderContent>
      )
    }
  }

  announceTimer () {
    if (this.props.iceTopNavBarEnabled && this.props.onTake && this.props.timerRunning) {
      return (
        <ScreenReaderContent>
          {t('During the quiz, press Alt+Shift+T to have the remaining time read')}
        </ScreenReaderContent>
      )
    }
  }

  contentExport = (opts, sdkAddAlert) => {
    this.props.contentExport(opts).then((responseData) => {
      if (exportStatus(responseData.json.status) === EXPORT_STATUS_EXPORTING) {
        this.props.pollForExport(responseData.json.id, sdkAddAlert, this.props.activeAssignmentId)
      }
    })
  }

  handleMenuItemChange = (path) => {
    this.props.navigateTo(path)
  }

  isBuildingPage () {
    return 'building' === this.props.app
  }

  renderHeader () {
    if (this.props.iceTopNavBarEnabled) {
      const actionItems = [...this.props.actionItems]
      if (this.isBuildingPage()) {
        actionItems.push(
          <SDKBuildingButtons
            id="MoreButton"
            key="MoreButton"
            app={this.props.app}
            contentExportEnabled={this.props.contentExportEnabled}
            contentExport={this.contentExport}
            navigateToBanks={this.props.navigateToBanks}
            navigateToPreviewPrint={this.props.navigateToPreviewPrint}
            speedgraderLink={this.props.speedgraderLinksEnabled ? this.props.speedgraderLink : null}
            contextUuid={this.props.contextUuid}
            iceTopNavBarEnabled
            renderIcon={<IconMoreSolid />}
          />
        )
      }

      return (
        <div css={this.props.styles.topNavBar}>
          <SDKNavBar
            menuItems={this.props.menuItems}
            handleMenuItemChange={this.handleMenuItemChange}
            actionItems={actionItems}
          />
        </div>
      )
    }

    return (
      <>
        {this.renderHamburgerButton()}
        <div css={this.props.styles.content}>
          {this.props.children}
        </div>
        <SDKBuildingButtons
          app={this.props.app}
          contentExportEnabled={this.props.contentExportEnabled}
          contentExport={this.contentExport}
          navigateToBanks={this.props.navigateToBanks}
          navigateToPreviewPrint={this.props.navigateToPreviewPrint}
          speedgraderLink={this.props.speedgraderLinksEnabled ? this.props.speedgraderLink : null}
          contextUuid={this.props.contextUuid}
        />
      </>
    )
  }

  render () {
    return (
      <Header isNewTopNavBar={this.props.iceTopNavBarEnabled}>
        <SkipToContent isNewTopNavBar={this.props.iceTopNavBarEnabled}/>
        {this.announcePreview()}
        {this.announceTimer()}
        {this.renderHeader()}
      </Header>
    )
  }
}

function mapStateToProps ({ sidebar: { open }, context = {}, features = [] }, props) {
  return {
    sidebarOpen: open,
    speedgraderLink: speedGraderLink(context),
    speedgraderLinksEnabled: features.includes('speedgrader_links'),
    contentExportEnabled: features.includes('new_quizzes_exports'),
  }
}

function mapDispatchToProps (dispatch, { activeAssignmentId }) {
  return {
    navigateToBanks: () => dispatch(navigateTo('/banks')),
    navigateToPreviewPrint: () => dispatch(navigateTo(`/build/${activeAssignmentId}/preview?printOnLoad=1`)),
    contentExport: ({ contextUuid, exportSettings }) => dispatch(contentExport({ contextUuid, exportSettings }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizHeader)
