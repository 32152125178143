const generateStyle = (componentTheme) => {
  return {
    content: {
      display: 'flex',
      flex: '1',
      alignItems: 'center'
    },
    topNavBar: {
      flexGrow: '1',
      flexShrink: '1'
    }
  }
}

export default generateStyle
